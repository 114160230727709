import React from 'react';

import './index.css';

const Body = ({ title, items }) => {
  return (
    <div className="quotes-bodys">
      <p>{title}</p>
      <ul className="specilization-list">
        {items.map((item, key) => (
          <li key={key}>
            <span></span>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Body;
