import React from 'react';

import './index.css';
import gc from '../../../img/gc-icon.png';
import aws from '../../../img/aws-icon.png';
import azure from '../../../img/azure-icon.svg';

import devopsIllustration from '../../../../static/img/devops-illustration@2x.jpg'

const HeroBody = () => {
  return (
    <section className="devops-hero-body-container">
      <div className="icons">
        <img
          src={gc}
          alt="gc"
          className="gc"
        />
        <img
          src={aws}
          alt="aws"
          className="aws"
        />
        <img
          src={azure}
          alt="azure"
          className="azure"
        />
      </div>
      <p>
        Your development and operations team can exist in peaceful harmony.
        <br />
        <br />
        Strategically integrating your development and operations capabilities
        into a cohesive unit can reduce time to market, increase user
        satisfaction, and improve your workplace culture.
        <br />
        <br />
        With a DevOps program, everything is organized, optimized, and
        automated. This optimization gives your team time to work on impactful
        tasks that contribute to your business’s goals. DevOps also unites your
        development and operations team so they can iterate faster and more
        often compared to traditional practices.
      </p>
      <img
          className='devops-illustration'
          src={devopsIllustration}
          alt='DevOps Illustration' 
        />
    </section>
  );
};

export default HeroBody;
