import React from 'react';

import './index.css';
import workDots from '../../img/work-dots.svg';

const LightBlueQuote = ({ title, text, body }) => {
  return (
    <section className="light-blue-quote secondary-padding">
        <img
          className="dots"
          src={workDots}
          alt="dots"
        />
        <div className="blog">
          <p className="c-title">{title}</p>
          <p className="subtitle">{text}</p>
        </div>
        {body && body}
    </section>
  );
};

export default LightBlueQuote;
