import React from 'react';

import BestBlogs from '../BestBlogs';
import './index.css';
import background from '../../img/quote-squiggle-desktop.svg';

export default ({ blogs, name, position, quote, image, title }) => {
  return (
    <section className="blue-quote">
      {blogs && (
        <img
          className="background"
          src={background}
          alt="Background"
        />
      )}
      <div className="top secondary-padding">
        <div className="flex">
          {image && <img src={image} alt="Quote" />}
          <div>
            <p className="name">{name}</p>
            <p className="position">{position}</p>
          </div>
        </div>
        <div className="quote">{quote}</div>
      </div>
      {blogs && <BestBlogs items={blogs} title={title} />}
    </section>
  );
};
