import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

import './index.css';

// Sections
import Hero from '../../components/TechnologyHero';
import HeroBody from '../../components/devopsSections/HeroBody';
import Quote from '../../components/Quote';
import EllipseQuoteSection from '../../components/TopOrangeEllipseQuote';
import QuoteBody from '../../components/devopsSections/QuotesBodys';
import WhiteQuoteSection from '../../components/LightQuote';
import LightBlueQuoteSection from '../../components/LightBlueQuote';
import BestBlogs from '../../components/BestBlogs';
import FormSection from '../../components/Form';

const pageTitle = 'DevOps Services | AWS, Azure, GCP Engineers | Devetry';
const pageDescription =
  'Optimize your organization with DevOps services. Devetry is a AWS, Azure, and GCP partner.';

const DevOpsPageTemplate = ({ blogs, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="devops-section root-container">
        <Hero
          title="DevOps & Cloud"
          description="Maximize the efficiency and quality of your software."
          image={heroImage}
          body={<HeroBody />}
        />
        <Quote
          title="Google Cloud"
          name="Google Cloud"
          quote="High-performing DevOps teams deploy over 200 times more often compared to traditional IT teams."
        />
        <EllipseQuoteSection
          title="Backend Dev + DevOps"
          text={
            <span >
              DevOps workflows vary across industries and team sizes, so there
              is no one-size-fits-all cloud solution. That’s why our team works
              across all types of cloud infrastructures. We can create a custom
              cloud solution to increase automation and deployments while
              decreasing incident responses and unnecessary communication.
            </span>
          }
          body={
            <QuoteBody
              title="We Specialize In:"
              items={[
                'Architecting Cloud Infrastructure',
                'Cloud Migration Strategy',
                'Team & Release Processes (Agile, CI/CD)'
              ]}
            />
          }
        />
        <WhiteQuoteSection
          title="DataOps & Analytics"
          text={
            <span>
              Optimized data and analytics management can reduce the end-to-end
              cycle time between ideas and value-driven models and charts.
              Devetry works in a variety of data management frameworks and can
              help you create, deploy, manage, and integrate.
            </span>
          }
          body={
            <QuoteBody
              title="We Specialize In:"
              items={[
                'Data Management',
                'API Development & Integrations',
                'Business Intelligence Tools',
                'Data Visualization'
              ]}
            />
          }
        />
        <LightBlueQuoteSection
          title="Deployment & QA"
          text={
            <span>
              Does your development and quality assurance team work together to
              efficiently deploy, automate, and test? Devetry can help your team
              build automated testing and reduce manual time spent on
              unnecessary tasks. This automation and prioritization of
              responsibilities can free up resources, reduce overtime, and
              improve workplace culture throughout your organization.
            </span>
          }
          body={
            <QuoteBody
              title="We Specialize In:"
              items={['Deployment', 'Automation', 'QA & Testing']}
            />
          }
        />
        <BestBlogs items={blogs} title="Refine your strategic skills" />
        <FormSection />
      </main>
    </>
  );
};

const DevOpsPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const blogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const heroImage = data.file;

  return <DevOpsPageTemplate blogs={blogs} heroImage={heroImage} />;
};

DevOpsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default DevOpsPage;

export const pageQuery = graphql`
  query DevOpsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "DevOps/index" } }) {
      frontmatter {
        templateKey
      }
    }
    allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Leadership" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
    file(relativePath: {
      eq: "working-together4.jpg"
    }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
