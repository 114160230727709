import React from 'react';

import './index.css';
import orangeEllipse from '../../img/orange-ellipse.svg';

const OrangeEllipseQuote = ({ title, text, body }) => {
  return (
    <section className="top-orange-ellipse-quote secondary-padding">
      <img
        className="orange-ellipse"
        src={orangeEllipse}
        alt="orange-ellipse"
      />
      <div className="red-dot" />
      <div className="blog">
        <p className="c-title">{title}</p>
        <p className="subtitle">{text}</p>
      </div>
      {body && body}
    </section>
  );
};

export default OrangeEllipseQuote;
