import React from 'react';

import './index.css';
import workDots from '../../img/work-dots.svg';

const LightQuote = ({ title, text, body }) => {
  return (
    <section className="light-quote secondary-padding">
      <img
        className="dots"
        src={workDots}
        alt="dots"
      />
      <div className="blog">
        <p className="c-title">{title}</p>
        <p className="subtitle">{text}</p>
      </div>
      <div className="sub-body">
        {body && body}
      </div>
    </section>
  );
};

export default LightQuote;
